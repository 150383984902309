import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { apiGetRequest } from '@app/services/client/api';
import {
    CreditPortfolioApiModel,
    IChartDataApiModel,
    ICompanyYearRevenueApiModel,
    IMarketYearVolumeForecastApiModel,
} from '@app/models/features/investments';

export async function apiGetCreditPortfolioChart(): Promise<ApiDataResponse<CreditPortfolioApiModel>> {

    return apiGetRequest('~/invest/api/investments/credit_portfolio');
}

export async function apiGetMarketShareChart(): Promise<ApiDataResponse<IChartDataApiModel>> {
    // return testResponse(MOCK_MARKET_SHARE);

    return apiGetRequest('~/invest/api/public/investments/market_share');
}

export async function apiGetBusinessAreasChart(): Promise<ApiDataResponse<IChartDataApiModel>> {
    // return testResponse(MOCK_BUSINESS_AREAS);

    return apiGetRequest('~/invest/api/public/investments/business_areas');
}

export async function apiGetCompanyRevenueByYears(): Promise<ApiDataResponse<ICompanyYearRevenueApiModel[]>> {
    // return testResponse(MOCK_COMPANY_REVENUE_BY_YEARS);

    return apiGetRequest('~/invest/api/public/investments/revenue_by_years');
}

export async function apiGetMarketVolumeForecastByYears(): Promise<ApiDataResponse<IMarketYearVolumeForecastApiModel[]>> {
    return apiGetRequest('~/invest/api/public/investments/market_forecast');
}

