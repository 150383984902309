import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    required,
    requirePhone,
} from '@frontend/jetlend-core/src/validators';
import { put } from 'redux-saga/effects';
import { ClientType } from '@frontend/jetlend-core/src/models/account';
import {
    ICalculatorState,
    ICreditCalculatorResult,
} from '@app/models/features/borrowers';
import { IPhoneFormModel } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';
import { startRegistration } from '@app/ducks/common/registration';

export const VERSION = 2;
export const PREFIX = 'borrowers';

export const calculatorHandler = objectHandler<ICalculatorState>(PREFIX, 'calculator', () => ({
    loanAmount: 10000000,
    loanDuration: 36,
    revenueAmount: 5000000,
    companyAge: true,
    creditHistory: true,
    stableRevenue: true,
    employeeAmount: true,
}));

export const summaryHandler = objectHandler<ICreditCalculatorResult>(PREFIX, 'summary', () => ({
    probability_of_approval: 0,
    min_interest_rate_amount: 0,
    max_interest_rate_amount: 0,
    monthly_payment: 0,
}));

export const borrowersPhoneFormHandler = simpleFormHandler<IPhoneFormModel>(
    PREFIX, 'borrower-phone', {
        v2: {
            phone: [ required(), requirePhone() ],
        },
    }, {
        *onBeforeSubmit (values) {
            yield put(sendEvent('borrower-button'));

            return values;
        },
        apiMethod: () => ({ status: 'OK' }),
        *onSuccess(_, values) {
            yield put(startRegistration(ClientType.Borrower, values.phone));
        },
    }, {}
);