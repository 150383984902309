import { useRef } from 'react';
import { useDataRef } from './useDataRef';

/**
 * Создает постоянную ссылку на функцию для обработки события, всегда вызывая последнюю переданный callback.
 *
 * Это позволяет отказаться в передаче зависимостей и уменьшить количество ререндеров.
 * Данные внутри переданной функции будут автоматически обновляться.
 *
 * Данный хук полезен при оптимизации сложных компонентов, где важно передавать обработчики событий,
 * не вызывая их пересоздания.
 *
 * @param callback любой обработчик события.
 * @returns статичную ссылку на обработчик события
 * @category Hooks
 *
 * @example
 * // Создаем обработчик события с помощью хука useEventCallback
 * // Ссылка на didCellClicked будет постоянной на каждый рендер
 * const didCellClicked = useEventCallback(cellId => {
 *     // Здесь данные будут всегда актуальные, т.к. ссылка на внутренний обработчик сохраняется внутри хука автоматически
 *     if (isCellExists(props.data, cellId)) {
 *         props.openCellEditor(cellId);
 *     }
 * });
 *
 * return (
 *     // Компонент не будет перерисован, т.к. параметры не обновились
 *     <HighWeightTableComponent
 *         onCellClicked={didCellClicked}
 *     />
 * );
 *
 * @example
 * // Используйте хук, чтобы сделать стабильную ссылку на внешний обработчик
 * const didClosed = useEventCallback(onClose);
 *
 * return (
 *      <Modal onClose={didClosed}>
 *            ...
 *      </Modal>
 * );
 */
export default function useEventCallback<T extends ((...args: any[]) => any)>(callback: T): T {
    const callbackRef = useDataRef(callback);

    return useRef((...args) => {
        return callbackRef.current?.(...args);
    }).current as T;
}
