import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import {
    put,
    select,
} from 'redux-saga/effects';
import { simpleSagaHandlerV2 } from '@frontend/jetlend-core/src/ducks/saga_v2';
import {
    DocumentCategory,
    IDocumentApiModel,
    IDocumentsState,
} from '@app/models/sections/documents';

export const VERSION = 2;
export const PREFIX = 'documents';

export const documentsPageStateHandler = objectHandler<IDocumentsState>(
    PREFIX, 'state', () => ({
        documents: [],
        filteredDocuments: [],
        selectedCategory: DocumentCategory.DOCUMENT,
        search: '',
    })
);

export const updateDocumentsSagaHandler = simpleSagaHandlerV2(PREFIX, 'update', function* (documents: IDocumentApiModel[]) {
    const state: IDocumentsState = yield select(documentsPageStateHandler.selector);

    yield put(documentsPageStateHandler.update({
        documents,
    }));

    yield put(filterDocuments(state.search, state.selectedCategory));
});

export const updateDocuments = (documents: IDocumentApiModel[]) =>
    updateDocumentsSagaHandler.action(documents);

export const filterDocumentsSagaHandler = simpleSagaHandlerV2(PREFIX, 'filter', function* (payload: {search: string; filter: DocumentCategory}) {
    const {
        search,
        filter,
    } = payload;
    const state: IDocumentsState = yield select(documentsPageStateHandler.selector);

    const filteredDocuments = state.documents?.filter(document => {
        if (filter === DocumentCategory.ALL) {
            return document.title.toLowerCase().includes(search.toLowerCase());
        }
        return (
            document.category === filter && document.title.toLowerCase().includes(search.toLowerCase())
        );
    });

    yield put(documentsPageStateHandler.update({
        filteredDocuments,
    }));
});

export const filterDocuments = (search: string, selectedCategory: DocumentCategory) =>
    filterDocumentsSagaHandler.action({
        search,
        filter: selectedCategory,
    });

export const applyNewDocumentsFilterSagaHandler = simpleSagaHandlerV2(PREFIX, 'apply_filter', function* (selectedCategory: DocumentCategory) {
    const state: IDocumentsState = yield select(documentsPageStateHandler.selector);

    if (selectedCategory !== state.selectedCategory) {
        yield put(filterDocuments(state.search, selectedCategory));

        yield put(documentsPageStateHandler.update({
            selectedCategory,
        }));
    }
});

export const applyCategoryFilter = (selectedCategory: DocumentCategory) =>
    applyNewDocumentsFilterSagaHandler.action(selectedCategory);

export const searchDocumentsSagaHandler = simpleSagaHandlerV2(PREFIX, 'search', function* (search: string) {
    const state: IDocumentsState = yield select(documentsPageStateHandler.selector);

    const filteredDocuments = state.documents.filter(document => {
        if (state.selectedCategory === DocumentCategory.ALL) {
            return document.title.toLowerCase().includes(search.toLowerCase());
        }
        return (
            document.category === state.selectedCategory && document.title.toLowerCase().includes(search.toLowerCase())
        );

    });

    yield put(documentsPageStateHandler.update({
        search,
        filteredDocuments,
    }));
});

export const searchDocuments = (searchText: string) =>
    searchDocumentsSagaHandler.action(searchText);

export const clearSearchSagaHandler = simpleSagaHandlerV2(PREFIX, 'clear_search', function* () {
    const {
        documents,
        selectedCategory,
    }: IDocumentsState = yield select(documentsPageStateHandler.selector);

    const filteredDocuments = selectedCategory === DocumentCategory.ALL
        ? documents
        : documents.filter(document => (
            document.category === selectedCategory
        ));

    yield put(documentsPageStateHandler.update({
        filteredDocuments,
        search: '',
    }));
});

export const clearSearch = () =>
    clearSearchSagaHandler.action();
