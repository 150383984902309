import {
    BaseSectionWithDocuments,
    ISectionBasedProps,
} from '../common/common';

export type DocumentsSectionType = ISectionBasedProps<DocumentsSectionApiModel>;

export type DocumentsSectionApiModel = BaseSectionWithDocuments<IDocumentApiModel>

export interface IDocumentApiModel {
    /**
     * Название документа.
     */
    title?: string;
    /**
     * Описание документа.
     */
    description?: string;
    /**
     * Раздел документа.
     */
    category: DocumentCategory;
    /**
     * Является ли это ссылкой на внешний файл или обычный файл для скачивания
     */
    external: boolean;
    /**
     * Информация о файле для скачивания.
     */
    file: IDocumentFile;
    /**
     * Ссылка для перехода на внешний файл.
     */
    url: string | null;
    /**
     * Имя файла в URL для короткого доступа с помощью /doc-view/ роута
     */
    url_slug: string;
}

export enum DocumentCategory {
    ALL = 'all',
    DOCUMENT = 'document',
    INFORMATION_DISCLOSURE = 'public',
    ARCHIVE = 'archive',
}

export interface IDocumentFile {
    id: number;
    title: string;
    filename: string;
    filesize: number;
    url: string;
    date: Date;
    modified: Date;
    mime_type: string;
    subtype: string;
    icon: string;
}

export interface IDocumentsState {
    documents: IDocumentApiModel[];
    filteredDocuments: IDocumentApiModel[];
    selectedCategory: DocumentCategory;
    search: string;
}

export interface IDocumentConfig {
    text: string;
    icon: string;
    iconActive: string;
}
