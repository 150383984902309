import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import { IAccountStatusDescription } from '@app/models/common/account';
import { apiGetAccountStatuses } from '@app/services/client/accountService';

export const VERSION = 2;
export const PREFIX = 'account';

export const accountStatusesHandler = simpleApiHandler<IAccountStatusDescription[]>(
    PREFIX, 'statuses', apiGetAccountStatuses
);
